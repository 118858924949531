'use client'

import { useRouter } from 'next/navigation'
import { JSX, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import Link from 'next/link'
import Icon from '@/components/commons/icon'

export default function Redirect({ href }: { href: string }): JSX.Element {
  const router = useRouter()

  useEffect(() => {
    const timer = setTimeout((): void => {
      window.location.href = href
    }, 500)

    return () => clearTimeout(timer)
  }, [href, router])

  const url: URL = new URL(href)

  return (
    <div className="text-center">
      <Icon
        name="LoaderCircle"
        className="mx-auto size-8 animate-spin text-muted-foreground"
      />
      <div className="my-5 flex flex-col">
        <span className="font-medium tracking-tight">
          Redirecting to &#171; <strong>{url.hostname}</strong> &#187;.
        </span>
        <span className="font-medium tracking-tight">
          If you are not redirected, click the button below.
        </span>
      </div>
      <Link href={href}>
        <Button type="button" variant="default">
          Click here to be redirected
        </Button>
      </Link>
    </div>
  )
}
