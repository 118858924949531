'use client'

import { JSX } from 'react'
import { icons, LucideProps } from 'lucide-react'

interface IconProps extends LucideProps {
  name: keyof typeof icons
}

const Icon = ({ name, ...props }: IconProps): JSX.Element => {
  const IconComponent = icons[name]

  if (!IconComponent) {
    console.error(`Icon "${name}" does not exist in the icons object.`)
    return <span />
  }

  return <IconComponent {...props} />
}

export default Icon
